import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ForgetPassword() {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtp, setShowOtp] = useState(false);
    const [error, setError] = useState("");
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!showOtp) {
            try {
                const response = await axios.post(`${BASE_URL}/user/forgot-password`, { email });

                if (response.data && response.data.token) {
                    setToken(response.data.token);
                    setShowOtp(true);
                } else {
                    setError("Failed to process request. Please try again.");
                }
            } catch (err) {
                setError(err.response?.data?.message || "An error occurred. Please try again.");
            }
        } else {
            try {
                console.log("Sending OTP verification request...");
                console.log("Token:", token);
                console.log("OTP:", otp);

                const response = await axios.post(
                    `${BASE_URL}/user/verify-forgot-password-otp`,
                    { otp },
                    {
                        headers: {
                            'token': token,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log("OTP verification response:", response);

                if (response.data && response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    navigate("/newpassword");
                } else {
                    setError("Failed to verify OTP. Please try again.");
                }
            } catch (err) {
                setError(err.response?.data?.message || "An error occurred while verifying OTP.");
            }
        }
    };

    return (
        <div className="login-form d-flex justify-content-center align-items-center">
            <div className="wrapper">
                <div className="form">
                    <div className="screen-effect"></div>
                    <div className="glass-effect"></div>
                    <div className="form-bigbox">
                        <div className="formblock">
                            <div className="form-inner-block">
                                <div className="formbox">
                                    <h1 className="text-center">Forget Password</h1>
                                    <p>Enter your accurate email for which you want to reset your password</p>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Your Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                disabled={showOtp}
                                            />
                                        </div>
                                        {showOtp && (
                                            <div className="mb-4">
                                                <label className="form-label">OTP</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        )}
                                        <button type="submit" className="btn d-block mt-4 mb-4">
                                            {showOtp ? "Verify" : "Continue"}
                                        </button>
                                        <div className="other-opt">Go back to <Link to="/login"><b>Login Now</b></Link></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}