import React from "react";
import { Link } from "react-router-dom";

export default function Success() {
    return (
        <div className="login-form d-flex justify-content-center align-items-center">
            <div className="wrapper">
                <div className="form">
                    <div className="screen-effect"></div>
                    <div className="glass-effect"></div>
                    <div className="form-bigbox">
                        <div className="formblock">
                            <div className="form-inner-block">
                                <div className="formbox text-center">
                                    <div class="success-icon">
                                        <div class="success-icon__tip"></div>
                                        <div class="success-icon__long"></div>
                                    </div>
                                    <h1 className="text-center">Successfully</h1>
                                    <p className="mb-4">Your password has been reset successfully</p>
                                    <Link to="/login">
                                        <button type="submit" className="btn d-block mb-4">Continue</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
