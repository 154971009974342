import React, { createContext, useState, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatThreads, setChatThreads] = useState([]);

  const updateChatThreads = (newThread) => {
    setChatThreads(prevThreads => [newThread, ...prevThreads]);
  };

  return (
    <ChatContext.Provider value={{ chatThreads, setChatThreads, updateChatThreads }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);