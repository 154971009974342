import React, { useState } from 'react';
import UserProfile from './UserProfile';
import { useNavigate } from 'react-router-dom';

export default function Tabs({ handleToggleSidebar }) {
    const [activeTab, setActiveTab] = useState('Newton');
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const navigate = useNavigate();

    const tabNames = ['Newton', 'Curie', 'Sherlock', 'Birbal'];

    const handleToggleOffcanvas = () => {
        setIsOffcanvasOpen(!isOffcanvasOpen); // Toggle offcanvas
    };
    const handleCloseOffcanvas = () => {
        setIsOffcanvasOpen(false); // Close offcanvas
    };

    const handleLogoClick = () => {
        navigate('/'); // Navigate to /newton when logo is clicked
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsOffcanvasOpen(false);
        if (tab === 'Sherlock') {
            window.open('http://sherlock.ravian.ai', '_blank');
        } else if (tab === 'Birbal') {
            window.open('https://birbal.ravian.ai/', '_blank');
        }
    };

    return (
        <>
            <div className='header'>
                <div className='logobox'>
                    <button className='toggle' onClick={handleToggleSidebar}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>
                    <div className="logo" onClick={handleLogoClick}>
                        <img src="/images/logo.avif" alt="Ravin AI Logo" className="logo-image" />
                    </div>
                </div>
                <div className="tabs-toggle" onClick={handleToggleOffcanvas}>
                    <i className="fa fa-align-right"></i>
                </div>
                <div className={`tabs ${isOffcanvasOpen ? 'offcanvas-open' : ''}`}>
                    <div className="offcanvas-close" onClick={handleCloseOffcanvas}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                    {tabNames.map(tab => (
                        <div className={`tab ${activeTab === tab ? 'active' : ''}`} key={tab}
                            onClick={() => handleTabClick(tab)}>
                            {tab}
                        </div>
                    ))}
                </div>
                <UserProfile />
            </div>
        </>
    );
}
