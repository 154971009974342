import React from 'react';

export default function ChatItem({ chatmsg, isSelected, onClick }) {
    return (
        <div className={`chatbox ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            <div className="chat-item">
                <div className="chat-message">{chatmsg}</div>
            </div>
        </div>
    );
}