import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
  // const base_url = 'https://saas.ravian.ai/v1/api'; /main
  // const BASE_URL = "https://saas.ravian.ai/v1/api";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CORE_URL = process.env.REACT_APP_CORE_URL;

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const generateCoreToken = async (name, email) => {
        try {
            const response = await axios.get(`${BASE_URL}/user/generate-core-token`, {
                params: { name, email }
            });
            return response.data.core_token;
        } catch (error) {
            console.error("Error generating core token:", error);
            throw error;
        }
    };

    const checkUserJwt = async (coreToken) => {
        try {
            const response = await axios.get(`${CORE_URL}/auth/check-user-jwt`, {
                headers: { Authorization: `Bearer ${coreToken}` }
            });
            return response.data;
        } catch (error) {
            console.error("Error checking user JWT:", error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/user/login`, {
                email,
                password
            });
            console.log("Login successful:", response.data);
            console.log("Login successful:", response.data.token);

            if (response.data.token) {
                console.log('working if')
                localStorage.setItem('token', response.data.token);
                const username = response.data.data.name;
                localStorage.setItem('username', username);

                // Generate core token and check user JWT
                // const coreToken = await generateCoreToken(username, email);
                // const jwtCheckResponse = await checkUserJwt(response.data.token);
                localStorage.setItem('core_token', response.data.token);

                navigate('/');
            } else {
                setError('Login failed. Please try again.');
            }
        } catch (error) {
            console.error("Login failed:", error.response ? error.response.data : error.message);
            setError(error.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handleGoogleSuccess = async (response) => {
        const userInfo = jwtDecode(response.credential);
        console.log(userInfo);

        try {
            const apiResponse = await axios.post(`${BASE_URL}/user/social-login`, {
                email: userInfo.email,
                name: userInfo.name,
                socialLoginType: "GMAIL",
            });

            if (apiResponse.data && apiResponse.data.token) {
                localStorage.setItem('token', apiResponse.data.token);
                localStorage.setItem('username', userInfo.name);
                console.log('Username stored:', userInfo.name);

                // Generate core token and check user JWT
                const coreToken = await generateCoreToken(userInfo.name, userInfo.email);
                const jwtCheckResponse = await checkUserJwt(coreToken);

                // Extract and store only the access_token
                if (jwtCheckResponse && jwtCheckResponse.access_token) {
                    localStorage.setItem('core_token', jwtCheckResponse.access_token);
                }

                navigate("/");
            } else {
                setError("Google authentication failed. Please try again.");
            }
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handleGoogleFailure = () => {
        console.log('Google error');
        setError("Google Sign-In failed. Please try again.");
    };

    return (
        <div className="login-form d-flex justify-content-center align-items-center">
            <div className="wrapper"> 
                <div className="form">
                    <div className="screen-effect"></div>
                    <div className="glass-effect"></div>
                    <div className="form-bigbox">
                        <div className="formblock">
                            <div className="form-inner-block">
                                <div className="formbox">
                                    <h1>Login</h1>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="user@gmail.com"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Link to="/forgetpassword">Forget Password?</Link>
                                        </div>
                                        <button type="submit" className="btn d-block">Continue</button>
                                    </form>
                                    <div className="other-opt">or continue with</div>
                                    <ul>
                                        <li>
                                            <GoogleLogin
                                                onSuccess={handleGoogleSuccess}
                                                onError={handleGoogleFailure}
                                                scope="profile email"
                                                useOneTap
                                            />
                                        </li>
                                    </ul>
                                    <div className="other-opt">Don't have an account yet? <Link to="/register"><b>Register Here</b></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}