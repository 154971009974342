import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function UserProfile() {
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the username is stored in localStorage
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        } else {
            // If not in localStorage, you might want to fetch it from your app's state or context
            // For now, we'll use a default value
            navigate('/login');
        }
    }, []);

    const handleLogout = () => {
        // Remove the token and username from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('core_token');
        
        // Navigate to the login page
        navigate('/login');
    };

    return (
        <>
            {/* <div className='dropdown notify-box'>
                <button className='notification dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-bell"></i></button>
                <div className='green-dot'></div>
                <ul className="dropdown-menu">
                    <li><span>Notification</span></li>
                    <li><a className="dropdown-item" href="/">Hi, How are You? How can I help you!</a></li>
                    <li><a className="dropdown-item" href="/">Hi, How are You? How can I help you!</a></li>
                    <li><a className="dropdown-item" href="/">Hi, How are You? How can I help you!</a></li>
                    <li><a className="dropdown-item" href="/">Hi, How are You? How can I help you!</a></li>
                    <li><a className="dropdown-item" href="/">Hi, How are You? How can I help you!</a></li>
                </ul>
            </div> */}
            <div className="dropdown">
                <button type='button' className="user-profile dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="user-avatar">
                        <img src="images/user.avif" alt="Avatar" />
                    </div>
                    <div className="user-name">
                        {username}
                    </div>
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <button className="dropdown-item" onClick={handleLogout}>
                            <i className="fa fa-sign-out"></i> Log Out
                        </button>
                    </li>
                </ul>
            </div>
        </>
    )
}
