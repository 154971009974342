import React from 'react';

export default function ChatScreenTwo({ onUploadClick }) {
    // const previousSources = [
    //     "Source 1",
    //     "Source 2",
    //     "Source 3",
    //     "Source 4",
    //     "Source 5",
    //     "Source 6",
    //     "Source 7",
    //     "Source 8",
    //     "Source 9",
    //     "Source 10",
    // ];
    return (
        <>
            <div className="btn-bottom">
                <button className="custom-file-upload" onClick={onUploadClick}>
                    <i className="fa fa-upload upload-icon"></i>
                    Upload Excel/CSV
                </button>
            </div>
            {/* <div className='db-source-box'>
                <div className='row justify-content-center' style={{ alignItems: 'center' }}>
                    <div className='col-lg-12'>
                        <h3>Connect To Data Source</h3>
                    </div>
                    <div className='col-lg-5'>
                        <div className='data-source'>
                            <div className="upload-box mb-4 btn-bottom">

                            </div>
                            <div className="upload-box mb-4">
                                <button className="custom-file-upload">
                                    <i className="fa fa-upload upload-icon"></i>
                                    Upload JSON
                                </button>
                            </div>
                            <div className="upload-box mb-4">
                                <button className="custom-file-upload">
                                    <i className="fa fa-upload upload-icon"></i>
                                    Connect to DB
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 offset-lg-1'>
                        <div className='previous-ds'>
                            <h4>My Data Sources</h4>
                            <div className='ds-box'>
                                {previousSources.map((source, index) => (
                                    <div className="form-check" key={index}>
                                        <input className="form-check-input" type="checkbox" value="" id={`Source${index + 1}`} />
                                        <label className="form-check-label" htmlFor={`Source${index + 1}`}>
                                            {source}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button type='button' className='connect-btn'>Connect</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}
